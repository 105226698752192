import React from 'react'
import { Link } from 'gatsby'

import Tile from '../components/tile'

import ContentTile from '../components/content-tile'

import ProductListing from '../components/product-listing'





import styles from './tile.module.scss'






class HomepageTileCollapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false 
    };
   }

  showAll = () => {
       this.setState({
         open: true
       });
     }

  render() {
    const product = this.props.product;
    const allArticles = this.props.articles;
    const productCategories = this.props.product.homepageCategoryOrder;
    const allPages = this.props.pages;
    //const productTiles = this.props.product.homepageContentTiles;

    var productTiles = ''
    
    var excludeCategory = 'software-updates'


 // {supportProducts.map(({ node }) => {
 //    if(node.slug != excludedSlug) {
 //      return (
 //      <ProductListing key={node.slug} product={node}/>
 //    )  
 //    }
    if(this.props.product.homepageContentTiles) {
      productTiles =  this.props.product.homepageContentTiles.map((node, i) => {
       // if(node.slug != excludedSlug) {
                 return (
                   
                 <ContentTile key={node.id} fields={node} tilePages={allPages} product={product.slug} tileArticles={allArticles} iterator={i}/>
                  )
                })
    }
    else if (this.props.product.homepageCategoryOrder) {
      productTiles =  this.props.product.homepageCategoryOrder.map((category, i) => {
        if(category.slug != excludeCategory) {
        return (
        <Tile key={product.slug + '-' + category.slug} iterator={i} article={category} product={product.slug} tileArticles={allArticles} />    
        )
      }
      })
    }

    var panelClass = '';

    if (this.state.open) {
      panelClass = styles.showAll
    }

    

    // const post = get(this.props, 'data.contentfulArticle')
    // const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <div className={['panel panel-default', styles.supportPanel, panelClass].join(' ')} ref="tileContainer">
                    <div className="panel-heading">
                  <h4 className="panel-title">
                      <a className="accordion-toggle" data-toggle="collapse" data-parent="#jobs-parent" href={'#' + product.slug}>
                         {product.name}
                      </a>
                  </h4>
                  <ProductListing extraClass={'visible-xs'} product={product}/>

              </div>


              <div id={product.slug} className={[styles.productPanel, 'panel-collapse collapse'].join(' ')}>
              
              <section className={[styles.tileWrapper, 'text-center'].join(' ')}>
                  <div className={styles.container}>
                  {productTiles}
                  </div>
       <a onClick={this.showAll} className={"btn btn-default " + product.slug}>See all topics</a>
     </section>
     </div>

  
     </div>
    )
  }
  
}

export default HomepageTileCollapsible