import React from 'react'
import { Link } from 'gatsby'

import styles from './product-listing.module.scss'




class ProductListing extends React.Component {
  render() {
    const product = this.props.product;
    const slug = this.props.product.slug;

    var extraClass = ''

    var buttonSlug = slug;
    var replaceSlug = slug.replace(/-/g, "")
    //console.log("replaceSlug is "+replaceSlug)
    if(styles[replaceSlug]) {
    	buttonSlug = styles[replaceSlug]
    }

    if(this.props.extraClass) {
    	extraClass = this.props.extraClass
    }

    //mobile app redirect
    // if(slug == 'mobile-app'){
    //   return null;
    // }





return (
	<div className={[styles.departmentButton, buttonSlug, extraClass].join(' ')}>
      <a className={['collapsed', 'button', product.slug].join(' ')} role="button" data-toggle="collapse" data-parent="#jobs-parent" href={'#' + product.slug} aria-expanded="false" aria-controls={product.slug}>
        <img width={product.image ? product.image.file.details.image.width : null} height={product.image ? product.image.file.details.image.height : null} src={product.image ? product.image.file.url : null} alt={"Image of "+product.title}/>
        <h2>{product.title}</h2>
      </a>
      </div>
)
  }
}

export default ProductListing
